<template>
  <main-container>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>

      <v-col md="8" sm="12">
        <v-card class="pt-2">
          <v-card-title v-html="item.title"> </v-card-title>
          <v-card-subtitle
            v-if="item.sub != ''"
            class="text-left"
            :class="item.note != '' ? 'pb-0' : ''"
            v-html="item.sub"
          />
          <v-card-subtitle
            v-if="item.note != ''"
            class="text-left"
            v-html="item.note"
          />
          <v-card-subtitle class="text-left" v-if="this.dataLoaded">
            Next Update: {{ item.next }}
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text v-html="item.story" class="text-left black--text">
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
    </v-row>
  </main-container>
</template>

<script>
import { mapState } from "vuex";
import MainContainer from "../components/MainContainer.vue";
export default {
  components: { MainContainer },
  computed: {
    ...mapState(["dataLoaded"]),
    item() {
      return this.$store.state.freebie;
    },
  },
};
</script>

<style>
.shrinkPara {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
